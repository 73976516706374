import React from "react"
import loadable from '@loadable/component'

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import SEO from '../components/SEO'

const Logo = loadable(() => import("../components/Logo"));
const Menu = loadable(() => import('../components/Menu'));
const Header = loadable(() => import('../components/Header'));
const App = loadable(() => import('../components/App'));
const Content = loadable(() => import('../components/Content'));
const Features = loadable(() => import('../components/Features'));
const ImageFeature = loadable(() => import('../components/ImageFeature'));
const Section = loadable(() => import('../components/Section'));
const WhiteInnerSection = loadable(() => import('../components/WhiteInnerSection'));
const InnerSection = loadable(() => import('../components/InnerSection'));
const DogHanging = loadable(() => import('../components/DogHanging'));
const FAQ = loadable(() => import('../components/FAQ'));
const Ads = loadable(() => import('../components/Ads'));
const Video = loadable(() => import('../components/Video'));

export default function Home() {
  const breakpoints = useBreakpoint();

  return (
    <main>
      <SEO/>
      <Ads/>
      <Logo/>
      <Menu/>
      <Header/>
      {
        (() => {
          if(breakpoints.sm) {
            return <App/>
          }
        })()
      }
      <Content>
      <Video />
        {
          (() => {
            if(breakpoints.sm) {
              return <Features/>
            }
            else {
              return (
                <div id="app-features-section">
                  <App/>
                  <Features/>
                </div>
              )
            }
          })()
        }
        <Section title='Algumas Funcionalidades' id='more_features'>
          <div className='image_features_holder'>
            <ImageFeature img="img/ss1">
              <h4>Caminhadas</h4>
              <p>
                Passe a acompanhar e compartilhar as suas caminhadas
                e exercícios com seu pet, assim você pode entender melhor
                como a prática do exercício impacta o bem estar dele
              </p>
            </ImageFeature>
            <ImageFeature img="img/ss2" align='right'>
              <h4 className={`${(!breakpoints.md) ? '' : 'align-text-right'}`}>Cerca Virtual</h4>
              <p className={`${(!breakpoints.md) ? '' : 'align-text-right'}`}>
                Crie uma cerca virtual para ser notificado assim que seu pet
                sair dela, não corra mais o risco de perder seu pet
              </p>
            </ImageFeature>
            <ImageFeature img="img/ss3" align='left'>
              <h4>Temperatura Ambiente</h4>
              <p>
                Acompanhe a temperatura ambiente ao redor do seu pet,
                saiba as temperaturas nas quais ele se sente mais à vontade e mais
                se movimenta, vamos associar isso as suas caminhadas e brincadeiras,
                dessa forma você sabe que está sempre fazendo o melhor para ele
              </p>
            </ImageFeature>
            <ImageFeature img="img/ss4" align='right'>
              <h4 className={`${(!breakpoints.md) ? '' : 'align-text-right'}`}>Sono</h4>
              <p className={`${(!breakpoints.md) ? '' : 'align-text-right'}`}>
                O padrão do sono do seu pet é um dos principais indicativos da sua
                saúde, mudanças nesses padrões podem indicar uma série de problemas,
                mas fica tranquilo que assim que algo sair do padrão nós podemos te avisar
              </p>
            </ImageFeature>
            <ImageFeature img="img/ss5" align='left'>
              <h4>Relatórios</h4>
              <p>
                Relatórios e comparativos com os demais pets na plataforma e com os
                padrões da raça, indicando como foi a semana do seu pet
              </p>
            </ImageFeature>
            <ImageFeature img="img/ss6" align='right'>
              <h4 className={`${(!breakpoints.md) ? '' : 'align-text-right'}`}>Insights</h4>
              <p className={`${(!breakpoints.md) ? '' : 'align-text-right'}`}>
                Usando inteligência artificial geramos insights que indicam as melhores
                temperaturas, horários e formas para interagir com seu pet ou expondo ele
                a um ambiente rico em brinquedos e brincadeiras
              </p>
            </ImageFeature>
          </div>
        </Section>
        <Section title='Como Funciona' id='how'>
          <WhiteInnerSection side='left'>
            <p>
              Prenda em qualquer coleira e capture dados do seu cachorro,
              usamos inteligência artificial para entender melhor o seu amigo.
            </p>
          </WhiteInnerSection>
          <InnerSection style={{margin: '70px 25px 100px 25px'}}>
            <p>
              Você passa a acompanhar a saúde e localização do seu cachorro,
              além da suas atividades, sono e disposição.
            </p>
          </InnerSection>
          <WhiteInnerSection side='right'>
            <DogHanging />
            <p>
              Saiba como seu cachorro se sente e se há alguma coisa
              errada com a saúde do seu amigo.
            </p>
          </WhiteInnerSection>
        </Section>
        <Section title='FAQ' id='faq'>
          <InnerSection>
            <FAQ
              question='Quanto tempo dura a bateria?'
              answer='Depende do uso, quando dentro de casa e conetada
              ao celular via bluethooth, a bateria deve durar mais de 5-6
              dias.'
            />
            <FAQ
              question='Qual distância do sinal GPS?'
              answer='Nossa tecnologia para posição usa um mix de componentes,
              usamos principalmente satélites e redes móveis, o que significa
              que desde que seu cachorro esteja em uma área com sinal de celular
              você deve ter a posição dele, mesmo que longe de você ou de casa com
              uma ótima precisão'
            />
            <FAQ
              question='Funciona pra outros pets?'
              answer='Atualmente não, estamos trabalhando em uma versão para
              gatos, mas no momento toda tecnologia assume que o animal usando
              a coleira é um cachorro.'
            />

          </InnerSection>
        </Section>
      </Content>

      {/* RD Station */}
      <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/e0bc903a-3a06-4880-a685-c4cfb6f1b5d2-loader.js" ></script>
    </main>
  )
}